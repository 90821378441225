import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public subscriber: Subscription;

//en el constructor llamo mi router
constructor (private viewportScroller: ViewportScroller, private router: Router) {}

//En el ngOnInit, me subscribo al router events, que es el que te mandara los eventos cada que se inicie la navegación, y filtramos que solo te mande cuando termina de navegar NavigationEnd

ngOnInit () {
    // this.subscriber = this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event) => {
    //    console.log('The URL changed to: ' + event['url'])
    // });
 }

  //En el onDestroy, valido si mi subscriber sigue activo y me desuscribo, si no seguirá activo escuchando cuando navegues a otro componente donde no lo requieras.
//  ngOnDestroy () {
//     this.subscriber?.unsubscribe();
//  }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    

}