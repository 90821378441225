<section class="funfacts-area ptb-100 bg-image jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span>Number Speaks</span>
                    <h2>We always ready for a <b>challenge</b></h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a href="#" class="btn btn-primary">Learn More</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="counter-wrap">
                    <div class="single-counter">
                        <h2 class="odometer" data-count="1024">00</h2>
                        <h3>Successful projects</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="single-counter">
                        <h2 class="odometer" data-count="2023">00</h2>
                        <h3>Unique designs</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="single-counter">
                        <h2 class="odometer" data-count="4246">00</h2>
                        <h3>Happy customers</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>