<div class="main-banner item-bg3 ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content text-center">
                    <span class="wow fadeInUp" data-wow-delay="0.5s">Somos loc@s creativ@s</span>
                    <h1 class="wow fadeInUp" data-wow-delay="0.7s">Hazlo <b>Simple</b>, Hazlo <b>Efectivo</b></h1>
                    <p class="wow fadeInUp" data-wow-delay="0.9s">Tú brindas la idea y nosotros la hacemos crecer!.</p>
                    <div class="btn-box wow fadeInUp" data-wow-delay="1.1s">
                        <a (click)="onClick('about')" class="btn btn-primary">Iniciemos</a><!--href="#about"-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shapes/1.png" alt="shape1"></div>
    <div class="shape2 rotateme"><img src="assets/img/shapes/2.png" alt="shape2"></div>
    <div class="shape3 rotateme"><img src="assets/img/shapes/3.png" alt="shape3"></div>
    <div class="shape4"><img src="assets/img/shapes/4.png" alt="shape4"></div>
</div>
<app-features></app-features>

<app-about></app-about>

<app-cta></app-cta>

<app-services></app-services>

<app-who-we-are></app-who-we-are>

<app-skills></app-skills>

<app-portfolio></app-portfolio>

<app-why-we-different></app-why-we-different>

<app-funfacts-two></app-funfacts-two>

<app-team></app-team>

<app-story></app-story>

<!--<app-testimonial></app-testimonial>-->

<app-call-to-action></app-call-to-action>

<app-faq></app-faq>

<app-partner></app-partner>

<!-- <app-blog></app-blog> -->

<app-subscribe></app-subscribe>

<app-contact></app-contact>