<section id="team" class="team-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Conoce a nuestros expertos</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Nuestro equipo <b>Creativo</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">La belleza no es lo nuestro, pero talento es lo que sobra.</p>
        </div>
        <div class="team-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img2.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Andrés Pinilla</h3>
                        <span class="post">FrontEnd Developer</span>
                        <!-- <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img1.png" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">John Morales</h3>
                        <span class="post">CEO - UX/UI</span>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/john-morales-monardes/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/j.polox/" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img3.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Benjamín Lennon</h3>
                        <span class="post">FullStack Developer</span>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/benjam%C3%ADn-lennon-gonzalez-b13630162/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/benjlenng/" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img4.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Jhoan Zerpa</h3>
                        <span class="post">FullStack Developer</span>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/jhoan-z-473856a2" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.facebook.com/jhoan.zerpa.1" target="_blank"><i class="icofont-facebook"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img5.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Nelson Gallego</h3>
                        <span class="post">Backend Developer</span>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/nelson-gallego-tec-dev" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/nelsin_06/" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img6.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Victor Vargas</h3>
                        <span class="post">UX/UI Designer</span>
                    </div>
                </div>
            </div>

            
            <div class="team-box">
                <img loading="lazy" src="assets/img/team-img7.jpeg" alt="team-image">
                <div class="box-content">
                    <div class="box-inner-content">
                        <h3 class="title">Jose Cruz</h3>
                        <span class="post">FrontEnd Developer</span>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/jcruzal97" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/josecruz.97/" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>