import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { MipymedigitalComponent } from './components/pages/mipymedigital/mipymedigital.component';
import { SubscribeComponent } from './components/layouts/subscribe/subscribe.component';
import { LoginComponent } from './components/pages/login/login.component';
import { AuthGuard } from './protected/auth/auth-guard.service';

const routes: Routes = [
    {path: '', component: HomeFourComponent},
    {path: 'blog-1', component: BlogOneComponent},
    {path: 'blog-2', component: BlogTwoComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'mipymedigital', component: MipymedigitalComponent},
    {path: 'subscriptions', component: SubscribeComponent},
    {path: 'login', component: LoginComponent},
    // Here add new pages component

    // Auth route
    {   path: 'auth',
        loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule )    
    },

    // Protected route
    {
        path: 'dashboard',
        loadChildren: () => import('./protected/protected.module').then( m => m.ProtectedModule ), canActivate: [AuthGuard]
    },
    
    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {  preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule { }