import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";


export interface Subscriber {
  id: number;
  email: string;
}
/*
const ELEMENT_DATA: Subscriber[] = [
  {index: 1, email: 'ydrogen@gmail.com'},
  {index: 2, email: 'elium@gmail.com'},
  {index: 3, email: 'ithium@gmail.com'},
  {index: 4, email: 'eryllium@gmail.com'},
  {index: 5, email: 'oron@gmail.com'},
  {index: 6, email: 'arbon@gmail.com'},
  {index: 7, email: 'itrogen@gmail.com'},
  {index: 8, email: 'xygen@gmail.com'},
  {index: 9, email: 'luorine@gmail.com'},
  {index: 10, email: 'eon@gmail.com'},
];*/

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  displayedColumns: string[] = ['id', 'email'];
  //dataSource = ELEMENT_DATA;
  dataSource: any/*Subscriber[]*/ = [];
  suscripciones: any = [];
  
  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.retrieveSuscription();
  }

  retrieveSuscription(): void {
    
    this.http.get(environment.API_G + 'suscribirse').subscribe(
        data => {
          console.log(data);
        	//this.dataSource = data;
          this.suscripciones = data;
        },
        error => {
          console.log(error);
        });
  }


  logout(){
    this.router.navigateByUrl('/auth');
  }

}
