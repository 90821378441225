import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common'

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit() {
  }
  
  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
}

}
