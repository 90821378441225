<section class="funfacts-area-two ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.5s">
                    <i class="icofont-checked"></i>
                    <h3 class="odometer" data-count="52">00</h3>
                    <p>Proyectos exitosos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.7s">
                    <i class="icofont-ui-user"></i>
                    <h3 class="odometer" data-count="52">00</h3>
                    <p>Clientes felices</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="0.9s">
                    <i class="icofont-coffee-cup"></i>
                    <h3 class="odometer" data-count="1000">00</h3>
                    <p>Tazas de café</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact wow fadeInUp" data-wow-delay="1.1s">
                    <i class="icofont-crown-king"></i>
                    <h3 class="odometer" data-count="5">00</h3>
                    <p>Años de experiencia</p>
                </div>
            </div>
        </div>
    </div>
</section>