<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Información Importante</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Nuestras últimas <b>Noticias</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Entérate de nuestras últimas noticias y actualizaciones que tenemos para entregar. Programas, eventos, alianzas y mucho más.</p>
        </div>
        <div class="blog-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/mipymedigital"><img src="assets/img/blog1.jpeg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="/mipymedigital">Transformación Digital</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">21 Oct, 2021</span>
                    <h3><a routerLink="/mipymedigital">Lanzamiento "MiPymeDigital"</a></h3>
                    <p>Vivimos el lanzamiento de nuestra plataforma de acompañamiento en el camino hacia la transformación digital.</p>
                    <a routerLink="/mipymedigital" class="read-more-btn">Leer Más <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog2.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Agency</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">27 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">The best marketing top use management tools</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog3.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">IT</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">28 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog4.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Creative</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">29 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>