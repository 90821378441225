<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay="0.5s"><b>Contáctanos</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.7s">Si quieres saber más de nosotros o necesitas más información, ponte en contacto con nosotros.</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info wow fadeInUp" data-wow-delay="0.9s">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="icofont-google-map"></i> <span>Ubicación:</span>Baquedano 239, Oficina 316, Antofagasta.</li>
                                <li><i class="icofont-envelope"></i> <span>Email:</span><a href="mailto:contacto@tresidea.cl">contacto@tresidea.cl</a></li>
                                <li><i class="icofont-phone"></i> <span>Fono:</span><a href="tel:+56942403149">(+569) 4240 3149</a></li>
                                <li><i class="icofont-facebook"></i> <span>Facebook:</span><a href="https://facebook.com/tresidea" target="_blank">facebook.com/tresidea</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form wow fadeInUp" data-wow-delay="1.1s">
                    <!--<form id="contactForm">-->
                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(); contactForm.resetForm()" [formGroup]="formContacto" onsubmit="return false;">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input matInput type="text" class="form-control" formControlName="nombre" placeholder="Nombre">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input matInput type="email" class="form-control" 
                                    formControlName="email"
                                    placeholder="Email">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input matInput type="text" formControlName="asunto" class="form-control" placeholder="Asunto">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input matInput type="text" formControlName="fono" class="form-control" placeholder="Fono">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea matInput placeholder="Mensaje" 
                                    formControlName="mensaje" class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>