<section class="subscribe-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container wow fadeInUp" data-wow-delay="0.5s">
        <div class="newsletter">
            <h2>Únete a nuestro <b>Newsletter</b></h2>
            <form #contactForm="ngForm" class="newsletter-form wow fadeInUp" data-wow-delay="0.7s" (ngSubmit)="onSubmit(); contactForm.resetForm()" [formGroup]="formContacto" onsubmit="return false;">
                <input matInput type="email" class="form-control" placeholder="Correo electrónico" formControlName="email">
                <button type="submit">Subscribirme</button>
            </form>
        </div>
    </div>
</section>