import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(public router: Router) {
  }

  isAuthenticated() {
    
     let usuario:any = JSON.parse(localStorage.getItem('usuario'));
     console.log('usuario',usuario);
    if(!usuario || usuario == null){
      return false;
    }
    return true;
  }
}
