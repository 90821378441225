<section class="why-we-different ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Soluciones altamente creativas</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">¿Por qué somos <b>Diferentes</b>?</h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">A través de nuestra experiencia damos la posibilidad de convertir las ideas de nuestros clientes en soluciones Empáticas, Eficientes y Efectivas cuidando el Equipo de trabajo, Escalabilidad y Enfoque de tu proyecto de base tecnológica</p>
        </div>
        <div class="tab">
            <ul class="tabs wow fadeInUp" data-wow-delay="1.1s">
                <li><a href="#">
                    Desarrollo Ágil
                </a></li>
                <li><a href="#">
                    Trabajo Profesional
                </a></li>
                <li><a href="#">
                    Equipo experimentado
                </a></li>
                <li><a href="#">
                    Apoyo Constante
                </a></li>
            </ul>
            <div class="tab_content wow fadeInUp" data-wow-delay="1.3s">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Desarrollo Ágil</h3>
                                <p>Las mejores herramientas y metodologías ágiles a tu disposición, para que volemos juntos en los proceso de ideación y desarrollemos cosas maravillosas!</p>
                                <ul>
                                    <li>Lean UX</li>
                                    <li>Lean Start-up</li>
                                    <li>Lean Canvas</li>
                                    <li>Scope Canvas</li>
                                    <li>Design Sprint</li>
                                    <li>Scrum</li>
                                    <li>Kanban</li>
                                </ul>
                                <a (click)="onClick('portfolio')" class="btn btn-primary">Nuestro trabajo</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img loading="lazy" src="assets/img/why-we-different1.jpeg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img loading="lazy" src="assets/img/why-we-different2.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Trabajo Profesional</h3>
                                <p>Nuestro enfoque de diferenciación será siempre nuestra propuesta de valor hacia nuestros clientes, buscando entregar un producto de calidad que aporte un valor real y escalable a su negocio.</p>
                                <ul>
                                    <li>Uso de marco de trabajo ágil</li>
                                    <li>Entregas constantes</li>
                                    <li>Revisión, prueba y mejora</li>
                                    <li>Garantía de trabajo</li>
                                    
                                </ul>
                                <a (click)="onClick('portfolio')" class="btn btn-primary">Nuestro trabajo</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Equipo Experimentado</h3>
                                <p>Sin descansar y sin rendirnos frente a la vorágine del mundo emprendedor, hemos desarrollado un equipo de trabajo profesional y robusto para afrontar los desafíos que el negocio nos presenta.</p>
                                <ul>
                                    <li>Wireframing</li>
                                    <li>Experiencia de Usuario</li>
                                    <li>Diseño Gráfico</li>
                                    <li>Angular, React</li>
                                    <li>IONIC, React Native</li>
                                    <li>Transformación Digital</li>
                                    <li>Emprendimiento Digital</li>
                                    <li>Formulación de proyectos CORFO</li>
                                    
                                </ul>
                                <a (click)="onClick('portfolio')" class="btn btn-primary">Nuestro trabajo</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img loading="lazy" src="assets/img/why-we-different3.jpeg" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 why-we-different-img">
                            <div class="tabs_item_img">
                                <img loading="lazy" src="assets/img/why-we-different4.jpeg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 why-we-different-content">
                            <div class="tabs_item_content">
                                <h3>Apoyo Constante</h3>
                                <p>No descansaremos hasta que estés conforme con nuestro trabajo, somos un equipo y no te dejaremos sol@.</p>
                                <ul>
                                    <li>Reuniones Remotas</li>
                                    <li>Grupo de mensajería</li>
                                    <li>e-mail</li>
                                    <li>Llamadas telefónicas</li>
                                    <li>Visita a terreno</li>
                                    <li>Programas de apoyo gratuito</li>
                                </ul>
                                <a (click)="onClick('portfolio')" class="btn btn-primary">Nuestro trabajo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>