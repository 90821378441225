<!--<section class="page-title-area-dash">
</section>-->
<section class="page-title-area item-bg3">
  <div class="container">
      <h2>Suscriptions</h2>
  </div>
</section>

<section class="blog-details-area ptb-100">
  <div class="container">
      <div class="row">
          <div class="col-lg-12 col-md-12">
              <div class="sidebar">
                  <div class="widget widget_search">
                      <form>
                          <input type="text" class="form-control" placeholder="Search here...">
                          <button type="submit"><i class="icofont-search"></i></button>
                      </form>
                  </div>
                  <div class="widget widget_categories">
                      <h3 class="widget-title">
                          Suscriptions
                      </h3>
                      <ul>
                          <ng-container *ngFor="let s of suscripciones;">
                          <li><a href="#">{{s.email}}</a></li>
                          </ng-container>
                      </ul>
                  </div>
              </div>
          </div>
          
          <button (click)="logout()">
            Logout
        </button>
      </div>
  </div>
</section>
<!---
<div class="row">
    <div style="margin-top: 10%; margin-left: 10%; margin-bottom: 10%;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
          
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
        
        <button (click)="logout()">
            Logout
        </button>
    </div>
</div>-->