<section class="page-title-area item-bg3">
    <div class="container">
        <h2>Lanzamiento MiPymeDigital</h2>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="post-image">
                        <img src="assets/img/blog1.jpeg" alt="image">
                    </div>
                    <h3>La espera terminó!</h3>
                    <div class="blog-meta">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a>Admin</a></li>
                            <li><i class="icofont-clock-time"></i> Octubre 28, 2021</li>
                            <li><i class="icofont-ui-folder"></i> <a>Evento</a></li>
                        </ul>
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing. There are many that an variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by a injected humour or randomised words which don’t look even of slightly believable. If you are going to use a the passage of Lorem Ipsum you need to be sure there isn’t anything embarrassing.</p>
                    <blockquote class="blockquote">
                        <p>There are many variations of passages of Lorem the Ipsum available but the that as that majority have is suffered alteration.</p>
                    </blockquote>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>Randomised words which don’t look even slightly believable. If you are going a to use a passage you need to be sure there isn’t anything embarrassing. Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="post-tag-media">
                    <div class="row h-100 align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <ul class="tag">
                                <li><span>Tags:</span></li>
                                <li><a>Evento,</a></li>
                                <li><a>Transformación Digital,</a></li>
                                <li><a>CORFO</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-5">
                            <ul class="social-share">
                                <li><span>Share on:</span></li>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sidebar">
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">
                            Recent Posts
                        </h3>
                        <ul>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog1.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The most popular new top business apps</a></h5>
                                <p class="date">25 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog2.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The best marketing top use management tools</a></h5>
                                <p class="date">27 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog3.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h5>
                                <p class="date">28 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog4.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h5>
                                <p class="date">29 Feb, 2020</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>