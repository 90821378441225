<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Que dicen de nosotros</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Sus <b>Testimonios</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Cada paso, cada decisión, cada desarrollo lo hacemos con el corazón, tu feedback es realmente valioso para seguir creciendo.</p>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="1.1s">
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author1.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author2.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>Product Manager</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author3.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Alina Eva</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author4.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>Product Manager</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>