<section class="call-to-action ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="call-to-action-content">
            <div class="section-title">
                <span class="wow fadeInUp" data-wow-delay="0.5s">Ya es el momento</span>
                <h2 class="wow fadeInUp" data-wow-delay="0.7s">¿Listo para <b>Empezar</b>?</h2>
                <p class="wow fadeInUp" data-wow-delay="0.9s">No esperemos más y transformemos juntos esas ideas en una realidad.</p>
                <a (click)="onClick('contact')" class="btn btn-primary wow fadeInUp" data-wow-delay="1.1s">Contáctanos</a>
            </div>
        </div>
    </div>
</section>