<section id="portfolio" class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Últimos Proyectos</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Nuestro <b>Portafolio</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Mira alguno de nuestros últimos proyectos desarrollados a nuestros felices clientes.</p>
        </div>
        <div class="shorting-menu wow fadeInUp" data-wow-delay="1.1s">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".ux">UX/UI</button>
            <button class="filter" data-filter=".dev">Desarrollo FullStack</button>
            <button class="filter" data-filter=".td">Transformación Digital</button>
        </div>
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix ux dev">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.5s">
                        <a href="assets/img/work-img1.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img1.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Habikids</h3>
                                <span>UX/UI - Desarrollo FullStack</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix ux dev">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.7s">
                        <a href="assets/img/work-img2.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img2.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>KIDI</h3>
                                <span>UX/UI - Desarrollo FullStack</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix ux dev">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.9s">
                        <a href="assets/img/work-img3.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img3.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>NewYou</h3>
                                <span>UX/UI - Desarrollo Fullstack</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix dev">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.5s">
                        <a href="assets/img/work-img4.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img4.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>LMS Fundación MiNorte</h3>
                                <span>Desarrollo FullStack</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix dev">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.7s">
                        <a href="assets/img/work-img5.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img5.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>Sistema Programación Tareas - Vesol</h3>
                                <span>Desarrollo FullStack</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mix td">
                    <div class="single-work wow fadeInUp" data-wow-delay="0.9s">
                        <a href="assets/img/work-img6.jpeg" class="popup-btn"></a>
                        <div class="work-image">
                            <img loading="lazy" src="assets/img/work-img6.jpeg" alt="work-image">
                            <div class="work-overlay">
                                <h3>MiPymeDigital</h3>
                                <span>Transformación Digital</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>