<section class="story-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="story-content">
            <div class="section-title">
                <span class="wow fadeInUp" data-wow-delay="0.5s">Tus ideas valen oro</span>
                <h2 class="wow fadeInUp" data-wow-delay="0.7s">Construyamos <b>Juntos</b> tu próximo gran proyecto</h2>
                <a href="https://www.youtube.com/watch?v=n8tXGU4BZVc" class="video-btn popup-youtube wow fadeInUp" data-wow-delay="0.9s"><i class="icofont-ui-play"></i></a>
            </div>
        </div>
    </div>
</section>