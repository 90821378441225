<section class="page-title-area">
    <div class="container">
        <h2>Login</h2>
    </div>
</section>

<!--Login Page Starts-->
<section id="login">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card overflow-hidden" style="margin-top: 4%; margin-bottom: 10%;">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center">
                  <img src="../../../../assets/img/login.png" alt="" class="img-fluid" width="300" height="230">
                </div>
                <div class="col-lg-6 col-12">
                  <!-- <h4 class="mb-2 card-title">Login</h4> -->
                  <p>Bienvenido de vuelta, por favor accede a tu cuenta.</p>
                  <!-- <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                    <p class="mb-0">Login failed!</p>
                  </ngb-alert> -->
                  <form [formGroup]="loginForm">
                    <div class="form-group">
                      <input type="text" formControlName="username" class="form-control" placeholder="Username" required>
                      <!-- <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                        required</div> -->
                    </div>
                    <div class="form-group">
                      <input type="password" formControlName="password" class="form-control" placeholder="Old Password">
                      <!-- <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                      </div> -->
                    </div>
                    <!-- <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                      <div class="remember-me mb-2 mb-sm-0">
                        <div class="checkbox auth-checkbox">
                          <input type="checkbox" formControlName="rememberMe" class="form-control" id="rememberMe">
                          <label for="rememberMe"><span class="font-small-2 mb-3 font-weight-normal">Remember Me</span></label>
                        </div>
                      </div>
                      <a [routerLink]="['/pages/forgotpassword']">Forgot Password?</a>
                    </div> -->
                    <hr>
                    <div class="d-flex justify-content-between flex-sm-row flex-column">
                      <!-- <a [routerLink]="['/pages/register']" class="btn bg-light-primary mb-2 mb-sm-0">Register</a> -->
                      <a routerLink="/subscribers-table" class="btn btn-primary">Login</a>
                    </div>
                  </form>
                  <!-- <div class="d-flex justify-content-between align-items-center">
                    <h6 class="text-primary m-0">Or Login With</h6>
                    <ngx-spinner></ngx-spinner>
                    <div class="login-options">
                      <a class="btn btn-sm btn-social-icon btn-facebook mr-1"><span class="fa fa-facebook"></span></a>
                      <a class="btn btn-sm btn-social-icon btn-twitter mr-1"><span class="fa fa-twitter"></span></a>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Login Page Ends-->
  