import { Component, OnInit, Inject, NgZone, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formContacto: FormGroup;
  loading = false;
  nowDate: Date = new Date();

  constructor(
    private fb:FormBuilder, private http: HttpClient) { 
      this.formContacto = this.fb.group({
        'nombre' : ['', [Validators.required]],
        'fono': ['', []],
        'asunto': ['', [Validators.required]],
        'email': ['', [Validators.required, Validators.email]],
        'mensaje': ['', [Validators.required]]
      })
    }

  ngOnInit() {
  }

  
  onSubmit(){
    let fecha = this.nowDate.getFullYear()+'-'+(this.nowDate.getMonth() + 1) + '-' + (this.nowDate.getDate() + 1);

    const val = this.formContacto.value;

    let emailDestContacto: any =[];

        emailDestContacto.push(val.email);
        // emailDestContacto.push('john.morales@tresidea.cl');
        // emailDestContacto.push('john.morales@tresidea.cl');

    const dataContacto: any = {
      'nombre'    : val.nombre,
      'fono' : val.fono,
      'asunto' : val.asunto,
      'email'     : emailDestContacto.join(','),
      'mensaje'   : val.mensaje,
      'fecha'     : fecha,
      'tipo'      : 'Inbox',
      'respondido': false,
      'isRead'    : false,
    }

    if (this.formContacto.valid){

      Swal.fire('¡Muchas Gracias!','Le responderemos lo más pronto posible','success');

      
      this.http.post(environment.API_G + 'sendmail', dataContacto).subscribe(

        data => {
          let res:any = data;
          console.log("Mensaje enviado correctamente!");
        },
        err => {
          console.log(err);
          this.loading = false;
        },() => {
          this.loading = false;
        }
      )
    }
    else{
      Swal.fire({icon: 'error', title: 'Oops...', text: 'Debe llenar todos los campos'});
      this.formContacto.markAllAsTouched();
      return;
    }

  }


}
