<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Que hacemos</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Nuestros <b>Servicios</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Nuestros años de experiencia nos permiten entregar una suite de servicios acorde los nuevos desafíos del negocio.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.5s">
                    <div class="services-image">
                        <img loading="lazy" src="assets/img/services-img1.jpeg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Diseño UX/UI</h3>
                        <p>Diseño de experiencia e interfaz de Usuario a través de metodologías ágiles</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.7s">
                    <div class="services-image">
                        <img loading="lazy" src="assets/img/services-img2.jpeg" alt="image">
                        <div class="icon">
                            <i class="icofont-code"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Desarrollo FullStack</h3>
                        <p>Desarrollo de Software Web/Mobile a medida según las necesidades del cliente</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services wow fadeInUp" data-wow-delay="0.9s">
                    <div class="services-image">
                        <img loading="lazy" src="assets/img/services-img3.jpeg" alt="image">
                        <div class="icon">
                            <i class="icofont-rocket-alt-2"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Transformación Digital</h3>
                        <p>Diagnóstico Madurez Digital, diseño y enfoque modelo de negocios digital</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>