<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image wow fadeInUp" data-wow-delay="0.5s">
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img loading="lazy" src="assets/img/about-img1.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img loading="lazy" src="assets/img/about-img2.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img loading="lazy" src="assets/img/about-img3.jpeg" alt="about">
                        </div>
                    </div>

                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img loading="lazy" src="assets/img/about-img4.jpeg" alt="about">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content wow fadeInUp" data-wow-delay="0.7s">
                    <span>Nosotros</span>
                    <h2>Somos <b>Diseño</b> & <b>Desarrollo</b></h2>
                    <p>Una vez que la idea de negocio esté validada con nuestro proceso de diseño de experiencia de usuario, es necesario pasar a la siguiente etapa: Crear el <b>Producto Mínimo Viable</b> que te permita entregar valor a tus clientes</p>
                    <ul>
                        <li>Aplicaciones Stand-alone</li>
                        <li>Aplicaciones Web</li>
                        <li>Aplicaciones Móviles</li>
                    </ul>
                    <p>Somos un equipo experimentado, talentoso y apasionado por lo que hace.</p>
                </div>
            </div>
        </div>
    </div>
</section>