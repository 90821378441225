<section class="who-we-are ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span class="wow fadeInUp" data-wow-delay="0.5s">Quienes somos</span>
                    <h2 class="wow fadeInUp" data-wow-delay="0.7s">Somos <b>Tresidea</b></h2>
                    <p class="wow fadeInUp" data-wow-delay="0.9s">Desarrollamos soluciones y acompañamos tu éxito</p>
                    <a (click)="onClick('portfolio')" class="btn btn-primary wow fadeInUp" data-wow-delay="1.1s">Nuestro Trabajo</a>
                </div> <!--href="#portfolio"-->
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="content">
                    <p class="wow fadeInUp" data-wow-delay="0.5s">En nuestro núcleo vive el enfoque de Management 3.0, lo que nos hace posicionar a las personas en el centro de nuestra organización, entregando servicios de alto estándar para nuestros clientes sin descuidar el ser felices desarrollándolo.</p>
                    <ul class="wow fadeInUp" data-wow-delay="0.7s">
                        <li>Somos profesionales</li>
                        <li>Somos apasionados</li>
                        <li>Somos creativos</li>
                        <li>Somos tu apoyo</li>
                        <li>Somos locos</li>
                        <li>Somos felices</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>