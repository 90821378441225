<section class="cta-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3 class="wow fadeInUp" data-wow-delay="0.5s">Comienza a transformar tus ideas en Productos de <b>Valor</b></h3>
                    <span class="wow fadeInUp" data-wow-delay="0.7s">Otros ya lo están haciendo, no te quedes atrás</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a (click)="onClick('contact')" class="btn btn-primary">Inicia ahora</a><!--href="#contact"-->
            </div>
        </div>
    </div>
</section>