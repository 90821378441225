import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  loginFormSubmitted = false;
  isLoginFailed = false;

  loginForm : FormGroup = this.fb.group({
    email: ['', [ Validators.required, Validators.email ]],
    password: ['', [ Validators.required, Validators.minLength(6) ]]
  })

  // loginForm = new FormGroup({
  //   username: new FormControl('guest@apex.com', [Validators.required]),
  //   password: new FormControl('Password', [Validators.required]),
  //   rememberMe: new FormControl(true)
  // });
  constructor(  private fb: FormBuilder,
                private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    localStorage.removeItem('usuario');
  }

  login(){

    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    $('.preloader').fadeIn('slow');

    const login = {correo_login: this.loginForm.value.email, password: this.loginForm.value.password};

    this.http.post(environment.API_G + 'auth/signin', login)
      .subscribe(
        data => {
        
        console.log(data);
        localStorage.setItem('usuario',JSON.stringify(data));
        
        $('.preloader').fadeOut('slow');
        this.router.navigate(['/dashboard']);
        //this.router.navigate(['/subscriptions']);
        },
        error => {
          console.log(error);
          $('.preloader').fadeOut('slow');
          this.isLoginFailed = true;
        });

  }

}
