import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Nuestro foco',
            title: 'Sabemos lo que hacemos, nosotros iniciamos como tú',
            paragraphText: 'Llevamos tus ideas a un proyecto de base tecnológica siguiendo siempre nuestra ideología central, la experiencia de usuario.'
        }
    ]

    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-speech-comments',
            title: 'Ideamos juntos',
            paragraphText: 'Sacaremos a relucir el diseñador que llevas dentro.',
            activo: ''
        },
        {
            icon: 'icofont-heart-alt',
            title: 'Diseñamos con amor',
            paragraphText: 'Nos enamoramos del problema, no de la solución.',
            activo: 'Active'
        },
        {
            icon: 'icofont-smirk',
            title: 'Somos felices haciéndolo',
            paragraphText: 'Sí, somos muy felices, si hasta medimos la felicidad.',
            activo: ''
        }
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    paragraphText : string;
    activo: string;
}