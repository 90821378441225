<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">Clientes de confianza</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Nuestros Felices <b>Partners</b></h2>
        </div>
        <div class="partner-slides owl-carousel owl-theme wow fadeInUp" data-wow-delay="0.9s">
            <div class="partner-item">
                <a href="https://brinrock.cl/" target="_blank">
                    <img loading="lazy" src="assets/img/partner1.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="http://dmedia.cl/" target="_blank">
                    <img loading="lazy" src="assets/img/partner2.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a>
                    <img loading="lazy" src="assets/img/partner3.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="https://www.danimateluna.cl" target="_blank">
                    <img loading="lazy" src="assets/img/partner4.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="https://www.fmn.cl" target="_blank">
                    <img loading="lazy" src="assets/img/partner5.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="https://www.fcab.cl/" target="_blank">
                    <img loading="lazy" src="assets/img/partner6.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a>
                    <img loading="lazy" src="assets/img/partner7.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="https://www.finning.com/es_CL.html" target="_blank">
                    <img loading="lazy" src="assets/img/partner8.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="http://fulcroabc.com/" target="_blank">
                    <img loading="lazy" src="assets/img/partner9.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a href="https://pyaconsulting.cl/" target="_blank">
                    <img loading="lazy" src="assets/img/partner10.jpg" alt="img">
                </a>
            </div>
            <div class="partner-item">
                <a>
                    <img loading="lazy" src="assets/img/partner11.jpg" alt="img">
                </a>
            </div>
        </div>
    </div>
</section>