<section class="skill-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-content ptb-100">
                    <div class="section-title">
                        <span class="wow fadeInUp" data-wow-delay="0.5s">Estamos preparados</span>
                        <h2 lass="wow fadeInUp" data-wow-delay="0.7s">Algunas de nuestras <b>Habilidades</b></h2>
                        <p lass="wow fadeInUp" data-wow-delay="0.9s">Poseemos las habilidades necesarias para facilitar el entenimiento del problema y desarrollar un producto de calidad.</p>
                    </div>
                    <div class="skills">
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.5s">
                            <div class="skill-header">
                                <h3 class="skill-title">Metodologías ágiles</h3>
                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="100">0</span>%</div>
                                </div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.7s">
                            <div class="skill-header">
                                <h3 class="skill-title">Validación temprana</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="100">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="0.9s">
                            <div class="skill-header">
                                <h3 class="skill-title">Innovación</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="100">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item wow fadeInUp" data-wow-delay="1.1s">
                            <div class="skill-header">
                                <h3 class="skill-title">Tecnología</h3>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="100">0</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="skill-image">
                    <img loading="lazy" src="assets/img/skill-bg.jpeg" alt="skill-image">
                </div>
            </div>
        </div>
    </div>
</section>