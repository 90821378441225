// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API: 'http://localhost:4000/',
  //APIACAD: 'http://localhost:4000/', 
  // APIACAD: 'http://api-server.local/';
  //R_G: 'api/', // General
  //API_G: 'http://localhost:4000/api/', // General
  //API_img: 'http://localhost:4200/',

  API: 'https://tresidea.cl/',
  APIACAD: 'https://tresidea.cl/',
  // API: 'http://api-server.local/';
  // APIACAD: 'http://api-server.local/';
  R_G: 'api/', // General
  API_G: 'https://tresidea.cl/api/', // General
  API_img: 'https://tresidea.cl/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
