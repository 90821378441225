<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="wow fadeInUp" data-wow-delay="0.5s">FAQ</span>
            <h2 class="wow fadeInUp" data-wow-delay="0.7s">Preguntas <b>Frecuentes</b></h2>
            <p class="wow fadeInUp" data-wow-delay="0.9s">Es normal tener dudas, aquí respondemos aquellas de mayor frecuencia.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion accordion wow fadeInUp" data-wow-delay="0.5s" id="faqAccordion">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                ¿Puedo contratar sus servicios solo para desarrollo de software? <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Por supuesto, realizaremos un desarrollo según tus propios diseños o ideas de negocio. Nos hubiera encantado poder diseñar contigo, pero de igual forma haremos de tu proyecto, un proyecto exitoso.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                ¿Realizan Marketing Digital? <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                No, por el momento no entregamos dicho servicio.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                ¿Realizan diseños de logotipos e imagen de marca? <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Solo diseñamos logotipos de las Aplicaciones o plataformas que diseñamos.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Solo tengo una idea de proyecto, ¿Me pueden ayudar a mejorarlo?<i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Es lo que nos encanta hacer, sacaremos lo mejor de esa idea.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                ¿De qué forma ayudan a formular proyectos CORFO? <i class="icofont-dotted-down"></i>
                            </button>
                        </div>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Mediante asesorías personalizadas, te orientaremos en el enfoque que debes darle a tu postulación, además de los tips necesarios para el Pitch Day.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay="0.7s">
                    <img loading="lazy" src="assets/img/faq-bg.jpeg" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>