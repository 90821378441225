import { Component, OnInit, Inject, NgZone, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  formContacto: FormGroup;
  loading = false;

  constructor(private fb:FormBuilder, private http: HttpClient) {
    this.formContacto = this.fb.group({
      'email': ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit() {
  }

  onSubmit(){

    const val = this.formContacto.value;

    const dataContacto: any = {
      'email': val.email
    }

    if (this.formContacto.valid){

      Swal.fire('¡Muchas Gracias!','Se ha suscrito exitosamente','success');
      
      this.http.post(environment.API_G + 'suscribirse', dataContacto).subscribe(

        data => {
          let res:any = data;
          console.log("Mensaje enviado correctamente!");
        },
        err => {
          console.log(err);
          this.loading = false;
        },() => {
          this.loading = false;
        }
      )
    }
    else{
      Swal.fire({icon: 'error', title: 'Oops...', text: 'Debe llenar todos los campos'});
      this.formContacto.markAllAsTouched();
      return;
    }

  }

}
